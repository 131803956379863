module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://analytics.viadelweb.cloud","siteUrl":"https://viadelweb.com","matomoPhpScript":"piwik.php","matomoJsScript":"matomo.js","exclude":["/offline-plugin-app-shell-fallback/"],"requireConsent":false,"disableCookies":false,"cookieDomain":"*.viadelweb.com","dev":false,"enableJSErrorTracking":true,"trackLoad":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-187979754-1","G-FM4XJB8PHE"],"gtagConfig":{"anonymize_ip":false,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**"],"delayOnRouteUpdate":0,"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Via Del Web Web Design+Development & Digital Marketing","short_name":"Via Del Web","start_url":"/","background_color":"#217ebd","theme_color":"#217ebd","display":"minimal-ui","icon":"src/images/logo-only.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d0c4a69925fb84128e3eac62dbdfd88b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
